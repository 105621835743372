.single img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: black;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 20px;
  right: 40px;
  color: black;
}
.sliderWrap .wraperBanner {
  border-top: 1px solid black;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}
.sliderWrap .wrapperTop {
  border-bottom: 1px solid black;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}
.sliderWrap .tittle {
  color: black;
  position: absolute;
  top: 2px;
  left: 20px;
}
.sliderWrap .subtittle {
  color: black;
  position: absolute;
  top: 30px;
  left: 20px;
}
.sliderWrap .logoNexun {
  position: fixed;
  left: 20px;
  z-index: 9999;
}
.sliderWrap .photoNumber {
  opacity: 1;
  color: black;
  z-index: 9999;
  position: fixed;
  bottom: 18px;
  right: 50%;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  background-color: white;
  border-radius: 50%;
}

.fullScreenImage {
  width: 100%;
  max-height: 500px;
  position: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media screen and (max-width: 390px) {
  .sliderWrap .btnPrev {
    left: 10px;
  }
  .sliderWrap .btnNext {
    right: 10px;
  }
  .sliderWrap .btnClose {
    right: 10px;
  }
  .sliderWrap .photoNumber {
    right: 10px;
  }
  .sliderWrap .tittle,
  .sliderWrap .subtittle {
    left: 10px;
    max-width: calc(100% - 70px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
